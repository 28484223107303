

import { Vue, Component } from 'vue-property-decorator'
import { EvalutionRule, EvalutionTerm, EvaluationIndex, EvaluationLevel } from '@/types/evaluation'
import { Page } from '@/types/common'

@Component
export default class EvaluationRules extends Vue {
  tableData: EvalutionTerm[] = []
  tableData2: EvaluationIndex[] = []
  tableData3: EvaluationLevel[] = []
  dialogVisible1 = false
  dialogVisible2 = false
  isAllEdit = false
  isNoData = false

  created () {
    this.loadEvaluationTerm()
    this.loadEvaluationIndex()
  }

  loadEvaluationIndex () {
    this.$axios.get<EvaluationIndex[]>(this.$apis.evaluation.selectEvaluationIndexByList).then(res => {
      this.tableData2 = res.map(item => {
        item.isEdit = false
        return item
      })
    })
  }

  // 不能重复的姓名
  getExtraList (rowIndex: number) {
    const list: any[] = []
    this.tableData.forEach((item, index) => {
      if (rowIndex !== index) {
        list.push(item.termId)
      }
    })
    return this.tableData2.filter((item: any) => {
      return list.every((item1) => {
        return item.indexId !== item1
      })
    })
  }

  indexChange (value: string, row: EvalutionTerm, index: number) {
    const data = this.tableData2.find(item => item.indexId === value) as EvaluationIndex
    row.termName = data.indexName
    if (data.indexType === '0') {
      row.termChildName = ((data.indexList as EvaluationIndex[])[0]).indexName
    }
  }

  loadEvaluationLevel () {
    this.$axios.get<Page<EvaluationLevel>>(this.$apis.evaluation.selectEvaluationGradeByList).then(res => {
      this.tableData3 = res.list
    })
  }

  addEvaluationIndex () {
    this.tableData2.push({ indexName: '', indexType: '1', isEdit: true })
  }

  inputChange (value: string, index: number) {
    if (parseInt(value) >= 100) {
      value = '99'
      this.tableData3[index].gradeLo = value
    }
    if (index < this.tableData3.length - 1) {
      this.tableData3[index + 1].gradeUp = value
    }
  }

  addEvaluationLevel () {
    this.tableData3.push({
      gradeName: '',
      gradeUp: this.tableData3.length === 0 ? '100' : this.tableData3[this.tableData3.length - 1].gradeLo,
      gradeLo: '0'
    })
  }

  deleteEvaluationLevel (index: number) {
    this.tableData3.splice(index, 1)
  }

  saveEvaluationLevel () {
    const items = JSON.parse(JSON.stringify(this.tableData3)) as EvaluationLevel[]
    if (items[0] && +items[0].gradeUp !== 100) {
      this.$message({ type: 'warning', message: '得分区间满分为100分，请重新设置' })
      return
    }
    for (let i = 0; i < items.length; i++) {
      items[i].termSort = i
      if (i < items.length - 1) {
        const gradeLo = items[i].gradeLo
        const gradeUp = items[i + 1].gradeUp
        if (gradeLo !== gradeUp) {
          this.$message({ type: 'warning', message: '得分区间格式错误' })
          return
        }
      }
      if (!items[i].gradeName) {
        this.$message({ type: 'warning', message: '请输入考评等级' })
        return
      }
      if (parseInt(items[i].gradeLo) >= parseInt(items[i].gradeUp)) {
        this.$message({ type: 'warning', message: '下线需小于上线' })
        return
      }
    }
    if (parseInt(items[items.length - 1].gradeLo) !== 0) {
      this.$message({ type: 'warning', message: '得分区间格式错误' })
      return
    }
    this.$axios.post(this.$apis.evaluation.insertEvaluationGradeList, items).then(() => {
      this.dialogVisible2 = false
    })
  }

  deleteEvaluationIndex (row: EvaluationIndex, index: number) {
    if (row.indexId) {
      this.$axios.post(this.$apis.evaluation.deleteEvaluationIndex, {
        indexId: row.indexId
      }).then(() => {
        this.loadEvaluationIndex()
      })
    } else {
      this.tableData2.splice(index, 1)
    }
  }

  saveEvaluationIndex (row: EvaluationIndex) {
    let url = this.$apis.evaluation.insertEvaluationIndex
    if (row.indexId) {
      url = this.$apis.evaluation.updateEvaluationIndex
    }
    const data = JSON.parse(JSON.stringify(row))
    delete data.isEdit
    const repeatItem = this.tableData2.filter((item: EvaluationIndex) => item.indexName === row.indexName)
    if (repeatItem.length > 1) {
      this.$message.warning('指标名称不可重复！')
      return false
    }
    this.$axios.post(url, data).then(() => {
      this.loadEvaluationIndex()
    })
  }

  addEvaluationTerm () {
    const index = this.tableData.length
    this.addChildEvaluationTerm(index, 1)
  }

  addChildEvaluationTerm (index: number, colspan: number) {
    this.tableData.splice(index, 0, {
      add: true,
      isEdit: true,
      colspan: colspan,
      termId: '',
      termName: '', // 考评项
      termChildName: '', // 考评项名称
      termWeight: '', // 考评权重
      parentTermId: '',
      termSort: '', // 排序
      termDetailed: '' // 考评项细则
    })
    this.isAllEdit = true
    if (colspan === 0) {
      const prevIndex = this.findPrevIndex(index) as number
      (this.tableData[prevIndex].colspan as number)++
    }
  }

  findPrevIndex (currentIndex: number) {
    for (let i = currentIndex; i >= 0; i--) {
      if ((this.tableData[i].colspan as number) > 0) {
        return i
      }
    }
  }

  deleteEvaluationTerm (index: number) {
    console.log('index', index)
  }

  deleteChildEvaluationTerm (index: number) {
    const prevIndex = this.findPrevIndex(index) as number
    (this.tableData[prevIndex].colspan as number)--
    this.tableData.splice(index, 1)
  }

  editRow (row: EvalutionTerm, index: number) {
    this.isAllEdit = true
    this.tableData.forEach((item) => {
      item.isEdit = true
    })
  }

  saveEvaluationTerm () {
    const level1: any = []
    const level2: any = []
    const items = JSON.parse(JSON.stringify(this.tableData)) as EvalutionTerm[]
    items.forEach((item, index) => {
      if (item.colspan > 0) {
        level1.push({
          termId: item.termId,
          termName: item.termName,
          termWeight: item.termWeight,
          termSort: level1.length
        })
        for (let i = index; i < index + item.colspan; i++) {
          level2.push({
            parentTermId: item.termId,
            termName: items[i].termChildName,
            termDetailed: items[i].termDetailed,
            termSort: level2.length
          })
        }
      }
    })
    const count = level1.reduce((prev: string, next: any) => {
      return prev + parseInt(next.termWeight)
    }, 0)
    if (items.length === 0) {
      this.$message({ type: 'warning', message: '提示 ：至少增加一项' })
      return
    }
    if (count !== 100 && items.length > 0) {
      this.$message({ type: 'warning', message: '权重总和不为100，请调整' })
      return
    }
    this.$axios.post(this.$apis.evaluation.insertAllEvaluationTerm, level1.concat(level2)).then(() => {
      this.loadEvaluationTerm()
      this.isAllEdit = false
    })
  }

  showDialog1 () {
    this.dialogVisible1 = true
    this.loadEvaluationIndex()
  }

  showDialog2 () {
    this.dialogVisible2 = true
    this.loadEvaluationLevel()
  }

  loadEvaluationTerm () {
    this.$axios.get<EvalutionRule[]>(this.$apis.evaluation.selectEvaluationTermByList).then(res => {
      const items: EvalutionTerm[] = []
      res.forEach(item => {
        if (item.childNode && item.childNode.length) {
          item.childNode.forEach((childNode, index) => {
            items.push({
              add: false,
              isEdit: false,
              colspan: index === 0 ? item.childNode.length : 0,
              termId: item.termId,
              termName: item.termName, // 考评项名称
              termWeight: item.termWeight, // 考评权重
              termChildName: childNode.termName,
              parentTermId: item.termId,
              termDetailed: childNode.termDetailed // 考评项细则
            })
          })
        }
      })
      this.tableData = items

      if (this.tableData.length === 0) {
        this.isNoData = true
      } else {
        this.isNoData = false
      }
    })
  }

  objectSpanMethod ({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
      if (row.colspan > 0) {
        return {
          rowspan: row.colspan,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
